import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.useful-info-carousel');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.useful-info-carousel__carousel'),
        {
          slidesPerView: 'auto',
          watchOverflow: true,
          loop: false,
          spaceBetween: 0,

          navigation: {
            prevEl: carouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carouselBlock.querySelector('.swiper-button-next'),
          },

          a11y: {
            firstSlideMessage: _x(
              'This is the first slide',
              'swiper label',
              'vtx'
            ),
            lastSlideMessage: _x(
              'This is the last slide',
              'swiper label',
              'vtx'
            ),
            prevSlideMessage: _x('Go to previous slide', 'swiper label', 'vtx'),
            nextSlideMessage: _x('Go to next slide', 'swiper label', 'vtx'),
            slideLabelMessage: _x(
              'Slide {{index}} of {{slidesLength}}',
              'swiper label',
              'vtx'
            ),
            paginationBulletMessage: _x(
              'Go to slide {{index}}',
              'swiper label',
              'vtx'
            ),
          },
        }
      );
    });
  }
});
